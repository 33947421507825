import Head from 'next/head';
import { ReactNode } from 'react';
import TopNav from './header';

interface LayoutProps {
    loading: boolean;
    children?: ReactNode;
}

const Layout = ({ loading, children }: LayoutProps) => {
    return (
        <div style={{ height: '100%' }} className="flexColumn">
            <Head>
                <title>Mrs Wordsmith</title>
                <meta name="viewport" content="initial-scale=1.0, width=device-width" />
            </Head>
            <TopNav loading={loading} />
            <div id="page-wrap" className="flexColumn">
                {children}
            </div>
        </div>
    );
};

export default Layout;
