import { IToken, UserRole } from '@mws/wordengine-graph';
import jwtDecode from 'jwt-decode';
import { useEffect, useState } from 'react';
import { getCookie } from '../utils/utils';

export const useUserRoles = () => {
    const [userRoles, setUserRoles] = useState<UserRole[]>([]);

    useEffect(() => {
        const userToken = jwtDecode(getCookie('access_token')) as IToken;
        setUserRoles(userToken?.p || []);
    }, []);

    return userRoles;
};
