import nextCookie from 'next-cookies';
import Router from 'next/router';
import { GetServerSidePropsContext, NextPageContext } from 'next';
import { Response } from 'express';
import { FetchResult } from '@apollo/client';
import { Env, Environment } from '../config/runtimeConstants';
import { ParseResult } from 'papaparse';

export class ServerError extends Error {
    constructor(public message: string, public code: number) {
        super(message);
    }
}

export class UnauthorizedError extends ServerError {
    constructor(public message: string) {
        super(message, 401);
    }
}

export const respond = async (response: Response, result: any) => {
    let resolvedResult;
    try {
        resolvedResult = await result;
    } catch (e) {
        if (e.code === 'EntityNotFound') {
            console.log(e);
            response.status(404).json({
                code: e.code || e.name,
                message: e.message || e.sqlMessage
            });
        } else {
            console.error(e);
            response.status(e.code || 500).json({
                code: e.code || e.name,
                message: e.message || e.sqlMessage
            });
        }

        return;
    }

    response.status(200).json(resolvedResult);
};

export function objectPromise(obj: {
    [key: string]: Promise<any> | any;
}): Promise<{ [key: string]: any }> {
    return Promise.all(
        Object.keys(obj).map((key) =>
            Promise.resolve(obj[key]).then((val) => ({ key: key, val: val }))
        )
    ).then((items) => {
        const result = {};
        items.forEach((item) => (result[item.key] = item.val));
        return result;
    });
}

export function setCookie(name, value, days) {
    let expires = '';
    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = '; expires=' + date.toUTCString();
    }
    document.cookie = name + '=' + (value || '') + expires + '; path=/ ';
}

export function getCookie(name: string, cookies = null): string | null {
    const nameEQ = name + '=';
    let ca;
    if (cookies) {
        ca = cookies;
    } else {
        ca = document.cookie.split(';');
    }
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

export function removeCookie(name) {
    document.cookie = name + '=; Max-Age=-99999999;';
}

export function serialize(form) {
    const arr = [];
    Array.prototype.slice.call(form.elements).forEach(function (field) {
        if (
            !field.name ||
            field.disabled ||
            ['file', 'reset', 'submit', 'button'].indexOf(field.type) > -1
        )
            return;
        if (field.type === 'select-multiple') {
            Array.prototype.slice.call(field.options).forEach(function (option) {
                if (!option.selected) return;
                arr.push(encodeURIComponent(field.name) + '=' + encodeURIComponent(option.value));
            });
            return;
        }
        if (['checkbox', 'radio'].indexOf(field.type) > -1 && !field.checked) return;
        arr.push(encodeURIComponent(field.name) + '=' + encodeURIComponent(field.value));
    });
    return arr.join('&');
}

export const domainMap: { [key in Env]: string } = {
    production: 'hub.mrswordsmith.com',
    staging: 'hub.staging.mrswordsmith.com',
    development: 'hub.development.mrswordsmith.com'
};

export const getClientEnvironment = (): Env =>
    (Object.keys(domainMap).find((key) => domainMap[key] === window?.location.hostname) ||
        'development') as Env;

/**
 * Fetch wrapper to support both client and server side fetches without specific auth/error/redirect logic
 * @param method HTTP method
 * @param url Relative url, omitting leading '/'
 * @param data POJO to serialise and send as request body
 * @param context Server side only context, may be falsy client side
 * @param redirectOnError Whether to redirect to relevant page on error, e.g redirect to /login for 401s
 */
async function request<T>(
    method: 'GET' | 'POST' | 'PATCH' | 'PUT' | 'DELETE',
    url: string,
    data: any,
    context?: NextPageContext | GetServerSidePropsContext,
    redirectOnError?: boolean,
    raw?: boolean,
    signal?: AbortSignal
): Promise<T> {
    const headers: any = {
        'Content-Type': 'application/json'
    };

    let environment: string;

    if (context?.req?.headers) {
        const access_token = nextCookie(context)['access_token'] || '';

        headers['Cookie'] = `access_token=${access_token};`;

        if (url.match(/^https?:\/\/.+/)) {
            url = url;
        } else if (url.match(/^api\/.+/)) {
            const localDev = process.env.LOCAL_DEV === 'true';
            url = `http${localDev ? '' : 's'}://${
                localDev ? 'localhost:3000' : domainMap[Environment]
            }/${url}`;
        } else {
            throw new Error('Invalid request');
        }
    } else {
        environment = headers.environment = getClientEnvironment();
        url = url.match(/^https?:\/\/.+/) ? url : '/' + url;
    }

    console.log(`Fetching ${method}: ${url}`);

    const response = await fetch(url, {
        method,
        headers,
        credentials: 'include',
        body: JSON.stringify(data),
        signal
    });

    if (response.headers.get('environment') !== environment) {
        console.warn(
            `Response for wrong environment. Expected '${environment}' but got '${response.headers.get(
                'environment'
            )}' This should only happen during development.`
        );
    }

    let json: any;
    if (response.status < 400) {
        try {
            if (raw) return response.blob() as unknown as T;
            const text = await response.text();

            // Parse date strings into Date objects using reviver function
            json = JSON.parse(text, dateReviver);

            if (response.ok) {
                return json as T;
            }
        } catch (error) {
            // Handle below
        }
    }

    if (redirectOnError) {
        if (response.status === 400) {
            await redirect('/400', context);
            return;
        } else if (response.status === 401) {
            await redirect('/login', context);
            return;
        } else if (response.status === 403) {
            await redirect('/403', context);
            return;
        } else if (response.status === 404) {
            await redirect('/404', context);
            return;
        } else if (response.status === 500) {
            await redirect('/500', context);
            return;
        }
    }

    const error =
        json || new Error(response.bodyUsed ? response.statusText : await response.text());
    console.error(error);
    throw error;
}

export async function redirect(url: string, context?: NextPageContext | GetServerSidePropsContext) {
    if (context?.res) {
        context.res.writeHead(302, {
            Location: url
        });
        context.res.end();
        return;
    } else {
        await Router.push(url);
        return;
    }
}

export const unpackGraphQLResult = <T>(result: FetchResult<T>): T => {
    if (result.errors) {
        throw result.errors;
    } else {
        return result.data;
    }
};

export const Get: OmitSecondArg<OmitFirstArg<typeof request>> = (
    url,
    context?,
    redirectOnError?,
    raw?
) => request('GET', url, undefined, context, redirectOnError, raw);

export const Post: OmitFirstArg<typeof request> = (url, data, context?, redirectOnError?, raw?) =>
    request('POST', url, data, context, redirectOnError, raw);

export const Patch: OmitFirstArg<typeof request> = (url, data, context?, redirectOnError?, raw?) =>
    request('PATCH', url, data, context, redirectOnError, raw);

export const Put: OmitFirstArg<typeof request> = (url, data, context?, redirectOnError?, raw?) =>
    request('PUT', url, data, context, redirectOnError, raw);

export const Delete: OmitFirstArg<typeof request> = (url, data, context?, redirectOnError?, raw?) =>
    request('DELETE', url, data, context, redirectOnError, raw);

export const isISODateString = (str: string) =>
    /^\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)$/.test(str);

export const dateReviver = (_key: string, value: any) =>
    isISODateString(value) ? new Date(value) : value;

export const slugify = (value: string): string =>
    value
        .normalize('NFD') // split an accented letter in the base letter and the acent
        .replace(/[\u0300-\u036f]/g, '') // remove all previously split accents
        .toLowerCase()
        .trim()
        .replace(/[^a-z0-9 ]/g, '') // remove all chars not letters, numbers and spaces (to be replaced)
        .replace(/\s+/g, '-'); // separator

export const toTitleCase = (str: string) =>
    str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());

// Utility types
export type OmitFirstArg<F> = F extends <R>(x: any, ...args: infer P) => Promise<R>
    ? <R>(...args: P) => Promise<R>
    : never;

export type OmitSecondArg<F> = F extends <R>(x: any, y: any, ...args: infer P) => Promise<R>
    ? <R>(a, ...args: P) => Promise<R>
    : never;

export type ThenArg<T> = T extends PromiseLike<infer U> ? U : T;

export type ArrayElement<ArrayType extends readonly unknown[]> =
    ArrayType extends readonly (infer ElementType)[] ? ElementType : never;

export type DeepPartial<T> = {
    [P in keyof T]?: DeepPartial<T[P]>;
};

// CSV Helper
export function getRowObjects<T>(result: ParseResult<unknown>, CSV_COLUMNS: string[]): T[] {
    // Remove the first row as it contains the header
    const [, ...rows] = result.data as string[][];
    return rows.map((cols) => {
        return cols.reduce((acc, item, colIndex) => {
            acc[CSV_COLUMNS[colIndex]] = item;
            return acc;
        }, {} as T);
    });
}
