import { useRouter } from 'next/router';
import { createContext, useReducer, Dispatch } from 'react';
import { ValueOf } from 'ts-essentials';

export enum Namespace {
    mrswordsmith = 'mrswordsmith',
    mcgrawhill = 'mcgrawhill'
}

export const NamespaceColor = {
    [Namespace.mrswordsmith]: '#9fcee5',
    [Namespace.mcgrawhill]: '#d18089'
} as const;

enum ActionType {
    CHANGE_NAMESPACE
}

interface State {
    namespace: Namespace;
    namespaceColor: ValueOf<typeof NamespaceColor>;
}

interface INamespaceContext {
    state: State;
    dispatch: Dispatch<IChangeNamespace>;
}

interface IChangeNamespace {
    type: ActionType.CHANGE_NAMESPACE;
    payload: Namespace;
}

const NamespaceContext = createContext<INamespaceContext>({
    state: {
        namespace: Namespace.mrswordsmith,
        namespaceColor: NamespaceColor[Namespace.mrswordsmith]
    },
    dispatch: () => null
});

const ChangeNamespace = (value: Namespace): IChangeNamespace => ({
    type: ActionType.CHANGE_NAMESPACE,
    payload: value
});

const reducer = (state: State, action: IChangeNamespace): State => {
    switch (action.type) {
        case ActionType.CHANGE_NAMESPACE:
            return {
                ...state,
                namespace: action.payload,
                namespaceColor: NamespaceColor[action.payload]
            };
        default:
            return state;
    }
};

const NamespaceProvider = ({ children }: { children: JSX.Element }) => {
    const router = useRouter();
    let namespace = router.query.namespace as string;

    if (!namespace && !(namespace in Namespace)) {
        namespace = Namespace.mrswordsmith;
    }

    const initialState: State = {
        namespace: Namespace[namespace],
        namespaceColor: NamespaceColor[Namespace[namespace]]
    };
    const [state, dispatch] = useReducer(reducer, initialState);
    return (
        <NamespaceContext.Provider value={{ state, dispatch }}>
            {children}
        </NamespaceContext.Provider>
    );
};

export { NamespaceContext, NamespaceProvider, ChangeNamespace };
