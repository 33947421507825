import { FC } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button
} from '@mui/material';
import { useState } from 'react';

export interface ConfirmationOptions {
    catchOnCancel?: boolean;
    variant: 'danger' | 'info' | 'other';
    title: string;
    fullWidth?: boolean;
    maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
    content?:
        | string
        | JSX.Element
        | ((
              resolve: (value?: any) => any,
              reject: () => any,
              setValue?: (value: any) => void,
              value?: any
          ) => JSX.Element);
}

interface ConfirmationDialogProps extends ConfirmationOptions {
    open: boolean;
    onSubmit: (value?: any) => void;
    onClose: () => void;
}

export const ConfirmationDialog: FC<ConfirmationDialogProps> = ({
    open,
    title,
    variant,
    content,
    fullWidth,
    maxWidth,
    onSubmit,
    onClose
}) => {
    const [value, setValue] = useState(undefined);
    const handleOkClick = () => {
        onSubmit(value);
        setValue(undefined);
    };

    return (
        <Dialog open={open} fullWidth={fullWidth ? true : false} maxWidth={maxWidth}>
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                {typeof content === 'string' ? (
                    <DialogContentText>{content}</DialogContentText>
                ) : typeof content === 'function' ? (
                    content(onSubmit, onClose, setValue, value)
                ) : (
                    content
                )}
            </DialogContent>
            <DialogActions>
                <>
                    <Button color="primary" title="Ok" onClick={handleOkClick}>
                        OK
                    </Button>
                    {variant === 'danger' && (
                        <Button
                            color="primary"
                            onClick={() => {
                                onClose();
                                setValue(undefined);
                            }}
                            title="Cancel"
                            autoFocus>
                            CANCEL
                        </Button>
                    )}
                </>
            </DialogActions>
        </Dialog>
    );
};
