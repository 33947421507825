import { createContext, useContext, useState, useRef, ReactNode } from 'react';
import { ConfirmationDialog, ConfirmationOptions } from './dialog';

const ConfirmationServiceContext = createContext<(options: ConfirmationOptions) => Promise<any>>(
    Promise.reject
);

export const useConfirmation = () => useContext(ConfirmationServiceContext);

interface ConfirmationServiceProviderProps {
    children?: ReactNode;
}

export const ConfirmationServiceProvider = ({ children }: ConfirmationServiceProviderProps) => {
    const [confirmationState, setConfirmationState] = useState<ConfirmationOptions | null>(null);

    const awaitingPromiseRef = useRef<{
        resolve: (value?: any) => void;
        reject: () => void;
    }>();

    const openConfirmation = (options: ConfirmationOptions) => {
        setConfirmationState(options);
        return new Promise<void>((resolve, reject) => {
            awaitingPromiseRef.current = { resolve, reject };
        });
    };

    const handleClose = () => {
        if (confirmationState.catchOnCancel && awaitingPromiseRef.current) {
            awaitingPromiseRef.current.reject();
        }

        setConfirmationState(null);
    };

    const handleSubmit = (value?: any) => {
        if (awaitingPromiseRef.current) {
            awaitingPromiseRef.current.resolve(value);
        }

        setConfirmationState(null);
    };

    return (
        <>
            <ConfirmationServiceContext.Provider value={openConfirmation}>
                {children}
            </ConfirmationServiceContext.Provider>

            <ConfirmationDialog
                open={Boolean(confirmationState)}
                onSubmit={handleSubmit}
                onClose={handleClose}
                {...confirmationState}
            />
        </>
    );
};
