import { AppProps } from 'next/app';
import * as Sentry from '@sentry/browser';
import React, { useEffect, useState } from 'react';
import { ApolloProvider } from '@apollo/client';
import ReactGA from 'react-ga';

import '../styles/styles.css';
import { getClientEnvironment } from '../lib/utils/utils';
import client from '../lib/services/apolloClient';
import Layout from '../components/layout';
import { ConfirmationServiceProvider } from '../components/shared/dialog/dialogService';
import { SnackbarProvider } from 'notistack';
import { Get } from 'src/lib/utils/utils';
import { CaptureConsole } from '@sentry/integrations';
import { snackbarRef, SnackbarUtilsConfigurator } from '../lib/snackbar';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { NamespaceProvider } from '../components/namespace-toggle/context';

const MyApp = ({ Component, pageProps, router }: AppProps) => {
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        ReactGA.initialize('UA-133178145-14');

        Sentry.init({
            dsn: 'https://999db7aa885e44b29c6d68427b1fd719@o164513.ingest.sentry.io/5562444',
            // release: process.env.C_VERSION, // TODO: How should this work?
            maxBreadcrumbs: 50,
            environment: getClientEnvironment(),
            integrations: [
                new CaptureConsole({
                    levels: ['error']
                })
            ]
        });

        const controller = new AbortController();
        const signal = controller.signal;

        const startLoading = async () => {
            setLoading(true);
        };

        const endLoading = async (route) => {
            if (route !== '/login') {
                try {
                    await Get<{ loggedIn: boolean }>(`api/login/verify`, null, true, null, signal);
                } catch (err) {
                    setLoading(false);
                    console.log(err);
                }
            }

            console.error = (...data) =>
                snackbarRef.enqueueSnackbar(data.join(), { variant: 'error' });
            setLoading(false);
            ReactGA.pageview(window.location.pathname + window.location.search);
        };

        router.events.on('routeChangeStart', startLoading);
        router.events.on('routeChangeComplete', endLoading);

        return () => {
            controller.abort();
            router.events.off('routeChangeStart', startLoading);
            router.events.off('routeChangeStart', endLoading);
        };
    }, []);

    return router.route === '/login' ? (
        <Component {...pageProps} />
    ) : (
        <div style={{ height: '100vh' }}>
            <NamespaceProvider>
                <ApolloProvider client={client}>
                    <LocalizationProvider dateAdapter={AdapterLuxon}>
                        <SnackbarProvider
                            maxSnack={3}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right'
                            }}>
                            <SnackbarUtilsConfigurator />
                            <ConfirmationServiceProvider>
                                <Layout loading={isLoading}>
                                    <Component {...pageProps} key={router.route} />
                                </Layout>
                            </ConfirmationServiceProvider>
                        </SnackbarProvider>
                    </LocalizationProvider>
                </ApolloProvider>
            </NamespaceProvider>
        </div>
    );
};

export default MyApp;
