export type Env = 'development' | 'staging' | 'production';

export const Environment: Env = (process.env.APP_ENV as Env) || 'development';

console.log('Environment: ', Environment);

export const Port: number =
    parseInt(
        (Environment as string) === 'test'
            ? (process.env.TEST_PORT as string)
            : (process.env.PORT as string)
    ) || 8000;

export const region =
    process.env.AWS_DEFAULT_REGION || (Environment === 'development' ? 'eu-west-2' : 'us-west-2');
